@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext);
body{
    background-color: #ffffff;
    font-family: 'Open Sans', Arial, Helvetica, sans-serif;
}

.App{
    margin: 0;
}

.header{
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f3f4;
}

.header > ul {
    padding: 0;
    display: flex;
    flex-direction: row;
}

.header > ul > li {
    list-style: none;
    margin: 0 2px;
}

.header > ul > li > a{
    display: block;
    padding: 15px 30px;
    background-color: #d3d3d3;
    text-decoration: none;
    color: #0b090a;
    transition: 0.1s padding, 0.1s border ;
}

.header > ul > li > a.active{
    border-bottom: 5px solid #e5383b;
    padding: 15px 30px 10px 30px;
}


.app-component{
    display: block;
    padding: 20px;
    background-color: #d3d3d3;
}

.footer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    background-color: #f5f3f4;
}

label {
    display: block;
    width: 100%;
    margin-bottom: 25px;
}

label > input {
    outline: transparent;
    width: calc(100% - 15px);
    display: flex;
    flex-direction: row;
    margin: 10px 10px 3px 5px;
    padding: 3px 2px 5px;
    border: none;
    background-color: transparent;
    text-align: right;
    border-bottom: 1px solid #b1a7a6;
    transition: 0.2s ease-in-out border;
}

label > input:focus {
    padding: 3px 2px 5px;
    border-bottom: 1px solid #a4161a;
}

hr{
    margin: 0;
}

button{
    background-color: #a4161a;
    color: #ffffff;
    margin: 0 10px 0 0;
    padding: 9px 10px 8px 10px;
    border: 1px solid #f5f3f4;
    outline: transparent;
}

button.reset{
    background-color: #161a1d;
}

.breakdown{
    background-color: #f5f3f4;
    margin-top: 20px;
    padding: 20px;
}

.footnote{
    text-align: center;
    margin: 0;   
}

.footnote > p{
    font-size: smaller;
}

.footnote > p > a{
    text-decoration: none;
    font-weight: bold;
    color: #000;
}

